import ELROND from "@/constants/elrond";
import axios, { AxiosInstance } from "axios";

const PATHS = {
  TOKEN_VALUE: "/token-value",
};

class MaiarTokensApi {
  client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: ELROND.MAIAR_TOKENS_API,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      timeout: 30000,
    });
  }

  async tokenValue(token: string = ELROND.EGLD_TOKEN, valueToken: string = ELROND.USDC_TOKEN): Promise<number> {
    try {
      const response = await this.client.get(PATHS.TOKEN_VALUE + `?fsym=${ token }&tsym=${ valueToken }`);

      return Number.parseFloat(response.data.value);
    } catch (e) {
      console.error(e);

      return 0;
    }
  }
}

const maiarTokensApi = new MaiarTokensApi();

export default maiarTokensApi;
