import BaseContract from "@/contracts/baseContract";
import { Account, TokenTransfer, Transaction } from "@multiversx/sdk-core";
import elrondHelper from "@/helpers/elrond";
import ELROND from "@/constants/elrond";
import BLOCKCHAIN from "@/constants/blockchain";

export const NFT_VIP_STAKING_CONSTANTS = {
  SET_NB_NFTS: 5,
  EXTRA_NFTS_PER_SET: 3,
};

export const NFT_NUTS_STAKING_FUNCTIONS = {
  STAKE: "stake",
  UNSTAKE: "unstake",
  CLAIM_REWARDS: "claim_rewards",
};

export class NftStakingContract extends BaseContract {
  private static nftStakingContracts: { [address: string]: NftStakingContract } = {};

  constructor(type: string, poolAddress: string, private readonly metaToken: string, private readonly token: string) {
    super(
      poolAddress,
      type === BLOCKCHAIN.NFT_POOL_TYPES.VIP ? "nft-vip-staking" : "nft-nuts-staking",
      "StakingContract"
    );
  }

  static getContract(type: string, poolAddress: string, metaToken: string, token: string): NftStakingContract {
    if (!(poolAddress in NftStakingContract.nftStakingContracts)) {
      NftStakingContract.nftStakingContracts[poolAddress] = new NftStakingContract(type, poolAddress, metaToken, token);
    }

    return NftStakingContract.nftStakingContracts[poolAddress];
  }

  // Endpoints
  async stake(account: Account, nonces: number[], positionNonce: number | null = null): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[NFT_NUTS_STAKING_FUNCTIONS.STAKE]([]).withGasLimit(
      Math.min(20000000 + 4500000 * nonces.length, ELROND.MAX_GAS)
    );

    const payments = nonces.map((nonce) => TokenTransfer.nonFungible(this.token, nonce));

    if (positionNonce) {
      payments.push(TokenTransfer.metaEsdtFromBigInteger(this.metaToken, positionNonce, 1));
    }

    interaction.withMultiESDTNFTTransfer(payments);

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }

  async unstake(account: Account, nonce: number, nbNfts: number): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[NFT_NUTS_STAKING_FUNCTIONS.UNSTAKE]([]).withGasLimit(
      Math.min(20000000 + 3000000 * nbNfts, ELROND.MAX_GAS)
    );

    interaction.withSingleESDTNFTTransfer(TokenTransfer.metaEsdtFromBigInteger(this.metaToken, nonce, 1));

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }

  async claimRewards(account: Account, nonce: number, nbNfts: number): Promise<Transaction> {
    await this.getContractAbi();

    const interaction = this.contract.methods[NFT_NUTS_STAKING_FUNCTIONS.CLAIM_REWARDS]([]).withGasLimit(
      Math.min(20000000 + 1500000 * nbNfts, ELROND.MAX_GAS)
    );

    interaction.withSingleESDTNFTTransfer(TokenTransfer.metaEsdtFromBigInteger(this.metaToken, nonce, 1));

    return await elrondHelper.buildAndSendInteraction(interaction, account);
  }
}
